import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	Code,
	ImportExample,
	ExampleProps,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { H1, H2, H3, H4, H5, H6 } from "../../text/heading/heading";
import { Ul } from "../../text/list/list";
import { StructureExampleToggle } from "../common";
import { Content } from "./content";

export const Meta: DocPageMeta = {
	title: "Content",
	category: "Structure",
	notepad: "https://hackmd.io/ldUtqZg_SoKZfcGHO94VSw",
};

/** @ignore */
const StructureExample = (props: ExampleProps) => (
	<Example {...props} customBar={({ id }) => <StructureExampleToggle id={id} />} />
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Content"
			subTitle="Wrap this invisible container around one or multiple components. Use it to control the padding, position, and alignment of the components."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Content} />
				<InlineMessage variant="warning">
					<Paragraph>
						Our structure components are strongly interconnected. Make sure to read the structure
						component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink>, to
						find out which components you need and how to combine them correctly.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The content component is the deepest nested structure component (read the structure
					component <GatsbyLink to="/lib/components/Structure/🧭 Guide">guide</GatsbyLink> for more
					information). Therefore, it should not be wrapped around other structure components. It
					should be wrapped around one or multiple “medium” components, such as a{" "}
					<GatsbyLink to="/lib/components/Text/Text container">text-container</GatsbyLink>. The
					following sections showcase how to use the content component to control the padding,
					position, and alignment of such components.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					As you can see in the example below, the default property settings of the content
					component result in <Code>$space--medium</Code> (1rem/16px) padding on all sides.
				</Paragraph>
				<StructureExample fn={defaultExample} />
				<Header.H3>Padding</Header.H3>
				<Paragraph>
					Use the <Code>padding</Code> property to adjust the amount of padding around the
					component(s) wrapped inside the content component. If you don’t want top and/or bottom
					padding, make use of the <Code>noPaddingTop</Code> and <Code>noPaddingBottom</Code>{" "}
					properties. These three properties can be used together.
				</Paragraph>
				<Header.H4>Large</Header.H4>
				<StructureExample fn={paddingLargeExample} />
				<Header.H4>Medium (default)</Header.H4>
				<StructureExample fn={paddingMediumExample} />
				<Header.H4>Small</Header.H4>
				<StructureExample fn={paddingSmallExample} />
				<Header.H4>None</Header.H4>
				<StructureExample fn={paddingNoneExample} />
				<Header.H4>No padding top</Header.H4>
				<StructureExample fn={noPaddingTopExample} />
				<Header.H4>No padding bottom</Header.H4>
				<StructureExample fn={noPaddingBottomExample} />
				<Header.H3>Centered</Header.H3>
				<Paragraph>
					Use the <Code>centered</Code> property to position the wrapped component(s) in the
					horizontal center of the page.
				</Paragraph>
				<Header.H4>Small</Header.H4>
				<Paragraph>
					A property value of <Code>small</Code> limits the width of the wrapped component(s) to a
					maximum of 37.5rem (600px).
				</Paragraph>
				<StructureExample fn={centeredSmallExample} />
				<Header.H4>Medium</Header.H4>
				<Paragraph>
					A property value of <Code>medium</Code> limits the width of the wrapped component(s) to a
					maximum of 56.25rem (900px).
				</Paragraph>
				<StructureExample fn={centeredMediumExample} />
				<Header.H3>Flex container</Header.H3>
				<Paragraph>
					The content component offers the same layout options as the parent element, or flex
					container, of a{" "}
					<a href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/">CSS flexbox</a>. That
					means you can use its <Code>flexDirection</Code>, <Code>flexWrap</Code>,{" "}
					<Code>justifyContent</Code>, <Code>alignItems</Code>, <Code>alignContent</Code> and{" "}
					<Code>gap</Code> properties to control the positioning and alignment of the component(s)
					wrapped inside a content component. The three examples below showcase how these properties
					enable you to achieve different layouts.
				</Paragraph>
				<Header.H4>Example A</Header.H4>
				<StructureExample fn={flexContainerExampleA} />
				<Header.H4>Example B</Header.H4>
				<StructureExample fn={flexContainerExampleB} />
				<Header.H4>Example C</Header.H4>
				<Paragraph>
					This example uses the <Code>gap</Code> property to get equal spacing between the children
					elements. The default value of <Code>gap</Code> is <Code>medium</Code>, but it can also be{" "}
					<Code>xxSmall</Code>, <Code>xSmall</Code>, <Code>small</Code> and <Code>large</Code>.
				</Paragraph>
				<StructureExample fn={flexContainerExampleC} />
				<Header.H3>Flex item</Header.H3>
				<Paragraph>
					The content component behaves like a{" "}
					<a href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/">CSS flexbox</a> child
					element, or flex item, when wrapped inside a{" "}
					<GatsbyLink to="/lib/components/Structure/Content.Layout">Content.Layout</GatsbyLink>{" "}
					component. That means you can use its <Code>flex</Code> property to control the size of a
					content component, as shown in example A. You can also use the <Code>alignSelf</Code>{" "}
					property to override the default cross-axis alignment (or the one specificied by the{" "}
					<Code>alignItems</Code> property of the{" "}
					<GatsbyLink to="/lib/components/Structure/Content.Layout">Content.Layout</GatsbyLink>{" "}
					component) for an individual content component, as shown in example B.
				</Paragraph>
				<Header.H4>Example A</Header.H4>
				<StructureExample fn={flexItemExampleA} />
				<Header.H4>Example B</Header.H4>
				<StructureExample fn={flexItemExampleB} />
				<Header.H2>Properties</Header.H2>
				<InlineMessage variant="warning">
					<Paragraph>
						Some property values are not supported by IE11. These are noted in the table below. When
						you use one of these values, check how bad it looks in IE11. It's up to a designer to
						decide whether it's acceptable or not. If not, tweak the design or check if a different
						(IE11 supported) property value achieves a satisfactory result.
					</Paragraph>
				</InlineMessage>
				<Knobs
					component={Content}
					initialProps={{
						children: (
							<>
								<H1>H1</H1>
								<H2>H2</H2>
								<H3>H3</H3>
								<H4>H4</H4>
								<H5>H5</H5>
								<H6>H6</H6>
							</>
						),
					}}
					customBar={({ id }) => <StructureExampleToggle id={id} />}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Wrap content components around non-structural components, such as a{" "}
								<GatsbyLink to="/lib/components/Text/Text container">text-container</GatsbyLink> or{" "}
								<GatsbyLink to="#">(dead link) form</GatsbyLink>. Use it to control their padding,
								position, and alignment.
							</>,
							<>
								<GatsbyLink to="/lib/components/Structure/Card">Cards</GatsbyLink> are not the only
								component the content component can be used inside of. It can, for instance, also be
								used inside <GatsbyLink to="#">(dead link) modals</GatsbyLink> or{" "}
								<GatsbyLink to="#">(dead link) sidepanels</GatsbyLink>.
							</>,
							<>
								Use the <Code>padding</Code>, <Code>noPaddingTop</Code>, and{" "}
								<Code>noPaddingBottom</Code> properties to control the amount of padding around the
								wrapped components.
							</>,
							<>
								Use the <Code>centered</Code> property to horizontally center the wrapped{" "}
								component(s) and limit the width to a maximum of either 600 or 900 pixels.
							</>,
							<>
								Use the <Code>flexDirection</Code>, <Code>flexWrap</Code>,{" "}
								<Code>justifyContent</Code>, <Code>alignItems</Code> and <Code>alignContent</Code>{" "}
								properties to control the positioning and alignment of the wrapped components.
							</>,
							<>
								Wrap multiple content components in a{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">
									Content.Layout
								</GatsbyLink>{" "}
								component.
							</>,
							<>
								When a content component is wrapped inside a{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">
									Content.Layout
								</GatsbyLink>{" "}
								component, use its <Code>flex</Code> property to control its size.
							</>,
							<>
								When a content component is wrapped inside a{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">
									Content.Layout
								</GatsbyLink>{" "}
								component, use its <Code>alignSelf</Code> property to override the cross-axis
								alignment set by the{" "}
								<GatsbyLink to="/lib/components/Structure/Content.Layout">
									Content.Layout
								</GatsbyLink>{" "}
								component’s <Code>alignItems</Code> property.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								You need to add padding around{" "}
								<GatsbyLink to="/lib/components/Structure/Card">card</GatsbyLink> components. Use
								the{" "}
								<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">grid</GatsbyLink>{" "}
								and{" "}
								<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">
									grid.section
								</GatsbyLink>{" "}
								components instead.
							</>,
							<>
								You need to add spacing between text components, such as{" "}
								<GatsbyLink to="/lib/components/Text/Heading">headings</GatsbyLink> and{" "}
								<GatsbyLink to="/lib/components/Text/Paragraph">paragraphs</GatsbyLink>. Use the{" "}
								<GatsbyLink to="/lib/components/Text/Text container">text-container</GatsbyLink>{" "}
								component instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Paragraph>
					The <Code>padding</Code> property's default value was renamed from "regular" to "medium".
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Content>
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const paddingLargeExample = () => (
	<Content padding="large">
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const paddingMediumExample = () => (
	<Content padding="medium">
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const paddingSmallExample = () => (
	<Content padding="small">
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const paddingNoneExample = () => (
	<Content padding="none">
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const noPaddingTopExample = () => (
	<Content noPaddingTop>
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const noPaddingBottomExample = () => (
	<Content noPaddingBottom>
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus volutpat
			tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
		</Paragraph>
	</Content>
);

const centeredSmallExample = () => (
	<>
		<Content centered="small">
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</>
);

const centeredMediumExample = () => (
	<>
		<Content centered="medium">
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
	</>
);

const flexContainerExampleA = () => (
	<Content justifyContent="space-between" alignItems="baseline">
		<H1>H1</H1>
		<H2>H2</H2>
		<H3>H3</H3>
		<H4>H4</H4>
		<H5>H5</H5>
		<H6>H6</H6>
	</Content>
);

const flexContainerExampleB = () => (
	<Content flexDirection="column" alignItems="flex-end">
		<H1>H1</H1>
		<H2>H2</H2>
		<H3>H3</H3>
		<H4>H4</H4>
		<H5>H5</H5>
		<H6>H6</H6>
	</Content>
);

const flexContainerExampleC = () => (
	<Content gap>
		<H1>H1</H1>
		<H2>H2</H2>
		<H3>H3</H3>
		<H4>H4</H4>
		<H5>H5</H5>
		<H6>H6</H6>
	</Content>
);

const flexItemExampleA = () => (
	<Content.Layout>
		<Content flex="1">
			<Paragraph>flex: 1</Paragraph>
		</Content>
		<Content flex="1">
			<Paragraph>flex: 1</Paragraph>
		</Content>
		<Content flex="2">
			<Paragraph>flex: 2</Paragraph>
		</Content>
	</Content.Layout>
);

const flexItemExampleB = () => (
	<Content.Layout alignItems="flex-start">
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit sem eu lectus
				volutpat tincidunt. Aenean interdum consectetur iaculis. Cras sit amet erat ante.
			</Paragraph>
		</Content>
		<Content>
			<Paragraph>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque magna massa, elementum ac
				odio ut, consequat blandit massa. Sed posuere placerat mauris, nec vulputate risus faucibus
				a. Vestibulum id lacus tincidunt, blandit nulla a, semper nisi. Aliquam sit amet gravida
				felis. Suspendisse potenti. Nam in sollicitudin nisi.
			</Paragraph>
		</Content>
		<Content alignSelf="flex-end">
			<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Paragraph>
		</Content>
	</Content.Layout>
);
